import React, {useEffect, useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import {Footer} from './components/Footer'
import {Header} from './components/Header'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {FAQ_ACCORDION_ITEMS, PRICE_TIERS} from './core/_const'
import {FreeYearModal} from './modals/FreeYearModal'

export function PricingPage() {
  const intl = useIntl()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  const registrationURL = document.location.protocol + '//' + BASE_DOMAIN + '/auth/registration'
  const [showFreeYearModal, setShowFreeYearModal] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [])

  const handleTierClick = (e: React.MouseEvent, openModal: boolean) => {
    if (openModal) {
      e.preventDefault()
      setShowFreeYearModal(true)
    }
  }

  return (
    <>
      <Header />

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className={clsx(' pricing-page-container')}>
          <FreeYearModal
            url={registrationURL}
            show={showFreeYearModal}
            onClose={() => setShowFreeYearModal(false)}
          />

          <section className='hero container text-center'>
            <h2 className='mb-12px fw-boldest text-center text-primary lh-sm pt-28'>
              {intl.formatMessage({id: 'PRICING_TITLE'})}
            </h2>

            <p className='text-center mb-10 mx-auto fw-bold'>
              {intl.formatMessage({id: 'PRICING_SUBTITLE'})}
            </p>

            <a
              className='btn btn-outline btn-primary hover-orange rounded-pill px-5 px-lg-8'
              href={registrationURL}
            >
              {intl.formatMessage({id: 'START_FREE'})}
            </a>
          </section>

          <section className='pricing-options-container container my-20'>
            <div className='d-flex flex-column flex-lg-row flex-nowrap row-cols-1 row-cols-lg-3 text-center justify-content-evenly align-items-center align-items-lg-stretch gap-10 gap-lg-5 mw-100 mb-3'>
              {PRICE_TIERS.map((tier, tierIndex) => (
                <div className='col flex-fill mw-500px' key={tier.tier_name}>
                  <a
                    href={tier.negotiable ? 'mailto:sales@goricompany.com' : registrationURL}
                    className='card h-100 mb-4 shadow-sm border border-2 border-primary'
                    onClick={(e) => handleTierClick(e, tier.openModal)}
                  >
                    <div
                      className={clsx(
                        'card-header py-3 justify-content-center align-items-center rounded-top-3',
                        {
                          'border-primary bg-primary': tier.negotiable,
                        }
                      )}
                    >
                      <h4
                        className={clsx('my-0 fw-bolder fs-1', {
                          'text-white': tier.negotiable,
                        })}
                      >
                        {intl.formatMessage({id: tier.tier_name})}
                      </h4>
                    </div>

                    <div className='card-body text-start d-flex flex-column justify-content-start'>
                      {tier.negotiable ? (
                        <p className='card-title pricing-card-title fw-bolder fs-3 text-center text-black'>
                          {intl.formatMessage({id: tier.price})}
                        </p>
                      ) : (
                        <p className='card-title pricing-card-title fw-bolder fs-3 text-center text-black'>
                          ${tier.price}
                          <small className='text-body-secondary'>/mo</small>
                        </p>
                      )}

                      <ul className='mx-auto w-100 mt-8 mb-4 fw-bold'>
                        {tier.feature_list.map((feature, featureIndex) => (
                          <li
                            key={featureIndex}
                            className={clsx('mb-1 text-black', {
                              'fw-bolder fs-5 text-primary': tierIndex !== 0 && featureIndex === 0,
                            })}
                            style={tierIndex !== 0 && featureIndex === 0 ? {listStyle: 'none'} : {}}
                          >
                            {intl.formatMessage({id: feature})}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </section>

          <section className='pricing-faq container mt-20'>
            <h2 className='mb-12 text-left lh-sm'>{intl.formatMessage({id: 'PRICING_FAQ'})}</h2>

            <div className='accordion' id='faqAccordion'>
              {FAQ_ACCORDION_ITEMS.map((accordionItem, index) => {
                const headingId = `heading-${index}`
                const collapseId = `collapse-${index}`

                return (
                  <div key={index} className='accordion-item'>
                    <h4 className='accordion-header' id={headingId}>
                      <button
                        className='accordion-button collapsed fs-3 fw-bold'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#${collapseId}`}
                        aria-expanded='false'
                        aria-controls={collapseId}
                      >
                        {intl.formatMessage({id: accordionItem.title})}
                      </button>
                    </h4>
                    <div
                      id={collapseId}
                      className='accordion-collapse collapse'
                      aria-labelledby={headingId}
                      data-bs-parent='#faqAccordion'
                    >
                      <div className='accordion-body'>
                        {accordionItem.hasLink ? (
                          <p className='mb-0'>
                            {intl.formatMessage({id: accordionItem.content})}
                            {` `}
                            <a href={accordionItem.link}>
                              {intl.formatMessage({id: accordionItem.linkLabel})}
                            </a>
                            {` `}
                            {intl.formatMessage({id: accordionItem.content2})}
                          </p>
                        ) : (
                          <p className='mb-0'>{intl.formatMessage({id: accordionItem.content})}</p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      </CSSTransition>

      <Footer />
    </>
  )
}
