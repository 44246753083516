export const PRICE_TIERS = [
  {
    tier_name: 'PRICING_TIER_NAME_1',
    negotiable: false,
    openModal: false,
    price: '0',
    feature_list: [
      'PRICING_TIER_1_FEATURE_LIST_ITEM_1',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_2',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_3',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_4',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_5',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_6',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_7',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_8',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_9',
      'PRICING_TIER_1_FEATURE_LIST_ITEM_10',
    ],
  },
  {
    tier_name: 'PRICING_TIER_NAME_2',
    negotiable: false,
    openModal: true,
    price: '10',
    feature_list: [
      'PRICING_TIER_2_FEATURE_LIST_ITEM_1',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_2',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_3',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_4',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_5',
      'PRICING_TIER_2_FEATURE_LIST_ITEM_6',
    ],
  },
  {
    tier_name: 'PRICING_TIER_NAME_3',
    negotiable: true,
    openModal: false,
    price: 'ENTERPRISE_PRICE_DESCRIPTION',
    feature_list: [
      'PRICING_TIER_3_FEATURE_LIST_ITEM_1',
      'PRICING_TIER_3_FEATURE_LIST_ITEM_2',
      'PRICING_TIER_3_FEATURE_LIST_ITEM_3',
    ],
  },
]

export const FAQ_ACCORDION_ITEMS = [
  {
    title: 'PRICING_FAQ_HEADING_1',
    content: 'PRICING_FAQ_CONTENT_1',
  },
  {
    title: 'PRICING_FAQ_HEADING_2',
    content: 'PRICING_FAQ_CONTENT_2',
  },
  {
    title: 'PRICING_FAQ_HEADING_3',
    content: 'PRICING_FAQ_CONTENT_3',
    content2: 'PRICING_FAQ_CONTENT_3a',
    linkLabel: 'PRICING_FAQ_CONTENT_3_LINK',
    link: 'PRICING_FAQ_CONTENT_3_LINK_LABEL',
    hasLink: true,
  },
]
