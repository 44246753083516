import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Button} from '../../../../_gori/partials/widgets'

type Props = {
  url?: string
  show: boolean
  onClose?: () => void
}

const FreeYearModal: React.FC<Props> = ({url, show, onClose}) => {
  const intl = useIntl()

  const handleContinue = () => {
    onClose?.()
    if (url) {
      window.location.href = url
    }
  }

  return (
    <Modal
      id='gori_modal_free_year'
      tabIndex={-1}
      centered
      show={show}
      onHide={onClose}
      dialogClassName='h-auto mw-500px'
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title className='fs-1'>
            {intl.formatMessage({id: 'PRICING_MODAL_TITLE'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <p className='fs-4'>{intl.formatMessage({id: 'PRICING_MODAL_CONTENT'})}</p>
          <p className='footnote'>
            <i>*{intl.formatMessage({id: 'PRICING_MODAL_FOOTNOTE'})}</i>
          </p>
        </Modal.Body>
        <Modal.Footer className='border-top-0'>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'CONTINUE'})}
              event={handleContinue}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {FreeYearModal}
