import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

export function Footer() {
  const intl = useIntl()
  return (
    <div className='bg-dark mt-auto'>
      <div className='container'>
        <footer className='d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-left align-items-sm-center py-3 mt-15 mb-20'>
          <div className='col-lg-2 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-start text-white fs-1 fw-bolder text-decoration-none'>
            <div className='me-10 d-flex align-items-center'>
              <Link to='/'>
                <img
                  src={toAbsoluteUrl('/media/logos/big-shipbae-logo-dark-mode.png')}
                  className='w-125px mx-5'
                  alt='big-logo-dark-mode'
                />
              </Link>
            </div>
            <div className='vr d-none d-sm-inline-block'></div>
          </div>

          <ul className='nav mt-4 mt-sm-0 col-lg-10 flex-column flex-sm-row'>
            <li className='nav-item'>
              <Link
                to='/help-center'
                target='_blank'
                rel='noreferrer'
                className='nav-link me-5 text-white fs-4'
              >
                {intl.formatMessage({id: 'HELP_CENTER'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/support' rel='noreferrer' className='nav-link me-5 text-white fs-4'>
                {intl.formatMessage({id: 'SUPPORT'})}
              </Link>
            </li>
            <li className='nav-item'>
              <a
                href='https://gori.ai/shipbae-update'
                target='_blank'
                rel='noreferrer'
                className='nav-link text-white fs-4'
              >
                {intl.formatMessage({id: 'SHIPBAE_UPDATE'})}
              </a>
            </li>
            <li className='nav-item'>
              <Link to='/service-terms' className='nav-link me-5 text-white fs-4'>
                {intl.formatMessage({id: 'TERMS_OF_SERVICE'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/privacy-policy' className='nav-link text-white fs-4'>
                {intl.formatMessage({id: 'PRIVACY_POLICY'})}
              </Link>
            </li>
          </ul>

          <div className='my-10 my-sm-10 col-12'>
            <div className='ms-4 col-12 text-white fs-4'>Gori Company</div>
            <div className='ms-4 col-12 text-white fs-4'>support@goricompany.com</div>
            <div className='ms-4 col-12 text-white fs-4'>(877) 467-4244</div>
            <div className='ms-4 col-12 text-white fs-4'>
              1047 S Placentia Ave, Fullerton, CA 92831
            </div>
          </div>

          <div className='mx-auto text-white'>
            &copy; {intl.formatMessage({id: 'COPYRIGHT'}, {year: new Date().getFullYear()})}{' '}
            {intl.formatMessage({id: 'GORI_COMPANY'})}
            {'. '}
            {intl.formatMessage({id: 'ALL_RIGHTS_RESERVED'})}
          </div>
        </footer>
      </div>
    </div>
  )
}
